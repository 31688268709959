import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PageTitle from "../components/PageTitle";
import Button from "../components/Button";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageTitle title={'Page Not Found'}/>

      <section className={'relative'}>
          <div className={'relative max-w-6xl mx-auto px-6 sm:px-8'}>
              <div className={'py-12 md:py-20'}>

                  <h1 className={"text-xl font-bold"}>Page Not Found</h1>
                  <p className={"mb-8"}>You just hit a page that doesn&#39;t exist...</p>
                  <Button path={'/'}
                          text={'Go Back To Home'}
                  />

              </div>
          </div>
      </section>


  </Layout>
)

export default NotFoundPage
