import * as React from "react";
import PropTypes from "prop-types"
import { Link } from "gatsby";


const Button = ({ path, text, className }) => {

  return (

    <Link className={`flex flex-row group text-black px-6 py-2 border-black border border-solid hover:bg-red hover:border-red transition ease-in-out duration-300 ${ className }`}
          to={path}
    >
      <span className={`uppercase group-hover:text-white transition ease-in-out duration-300 mr-1`}>{ text }</span>

      <svg className={`w-6 h-6 text-red group-hover:text-white transition ease-in-out duration-300`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
      </svg>
    </Link>

  );

}


Button.propTypes = {
  path: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired
}


export default Button;